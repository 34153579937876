@use '@/styles/utils/mixins.scss' as *;

.subMenuWrapper {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
  margin-top: 1.75rem;
  border-top: 0.063rem solid $primaryAlt;
  .actionList {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 1.875rem;
    list-style: none;
    width: 100%;
    li {
      width: 100%;
    }
  }
  .subMenuContainer {
    .subMenuList {
      border-inline-end: 0 !important;
      :global {
        .submenuToggle {
          background: none;
          border: none;
          cursor: pointer;
          margin-right: 1.25rem;
          color: currentColor;
          transition: all 0.2s ease-in-out;
        }

        .ant-menu-submenu-arrow {
          display: none;
        }

        .ant-menu-submenu-title,
        .ant-menu-item-only-child {
          padding: 0;
          padding-left: 0 !important;
          margin: 0;
          width: 100%;
          height: auto;
          line-height: 1.2;
          position: relative;
          z-index: 99;
          font-family: 'Fakt Pro', sans-serif;
        }
        .ant-menu-sub.ant-menu-inline
          > .ant-menu-submenu
          > .ant-menu-submenu-title {
          height: auto;
          line-height: 1.2;
          position: relative;
          z-index: 99;
        }
        .ant-menu-submenu.ant-menu-submenu-inline
          > .ant-menu-submenu-title
          > .ant-menu-title-content
          > a {
          pointer-events: none;
          &:hover,
          &:focus,
          &:active {
            color: $white;
          }
        }
        .ant-menu-title-content,
        .anticon {
          transition: none;
        }
        .ant-menu-submenu-title {
          border-radius: 1.25rem;
          color: $dark;
          font-size: 1rem;
          font-weight: bold;
          transition: all 0.2s ease-in-out;
        }
        .ant-menu-item.ant-menu-item-only-child .ant-menu-title-content {
          font-size: 1rem;
          font-weight: bold;
        }
        .menuIcon {
          display: flex;
          flex: 0 0 auto;
          align-items: center;
          justify-content: center;
          height: 1.25rem;
          width: 1.25rem;
        }

        .ant-menu-submenu-open > .ant-menu-submenu-title,
        .ant-menu-submenu-active > .ant-menu-submenu-title,
        .ant-menu-submenu-title:hover,
        .ant-menu-submenu-title:active {
          background-color: $primary;
          color: $white;
        }
        .ant-menu-submenu-open > .ant-menu-submenu-title {
          .submenuToggle {
            transform: rotate(-90deg);
          }
        }
        .ant-menu-submenu-open > .ant-menu-submenu-title {
          .menuIcon {
            transform: rotate(-90deg);
          }
        }
        .ant-menu-sub.ant-menu-inline > .ant-menu-item.ant-menu-item-selected {
          background-color: transparent;
          color: $primary;
        }
        .ant-menu-sub.ant-menu-inline
          > .ant-menu-submenu-open.ant-menu-submenu
          > .ant-menu-submenu-title,
        .ant-menu-sub.ant-menu-inline
          > .ant-menu-submenu-active.ant-menu-submenu
          > .ant-menu-submenu-title {
          background-color: $neutral01;
          color: $dark;
        }
        .ant-menu-sub.ant-menu-inline {
          background-color: $neutral01;
          position: relative;
          border-bottom-left-radius: 1.25rem;
          border-bottom-right-radius: 1.25rem;
          padding: 0 0.75rem;
          &::before {
            content: '';
            position: absolute;
            top: -1.25rem;
            left: 0;
            width: 100%;
            height: 1.25rem;
            background-color: $neutral01;
          }
        }

        .ant-menu-sub.ant-menu-inline
          .ant-menu-submenu-inline
          .ant-menu-item.ant-menu-item-only-child {
          .ant-menu-title-content {
            font-weight: normal;
            font-size: 0.875rem;
            a {
              color: $neutral07;
              font-weight: normal;
              &:hover,
              &:focus,
              &:active {
                color: $primary;
              }
            }
          }
          .menuIcon {
            display: none;
          }
        }
      }
    }

    .menuItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .menuLink,
    .menuText {
      flex-grow: 1;
    }

    .menuLink {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1.25rem;
      transition: none;
      color: inherit;
      text-wrap: auto;
      span {
        word-wrap: break-all;
        white-space: break-spaces;
      }
    }
    .menuListLink {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1.25rem;
      font-size: 1rem;
      font-weight: bold;
    }
    .menuSubListLink {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1.25rem;
      font-size: 0.875rem;
      color: $neutral07;
      &:hover,
      &:focus,
      &:active {
        color: $primary;
      }
    }
  }
  .seeAllLink {
    color: $primary;
    font-family: 'Fakt Pro', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    margin: 1rem 1.25rem;
    &:hover {
      color: $primaryAlt;
    }
  }
  :global {
    .ant-menu-light:not(.ant-menu-horizontal)
      .ant-menu-item:not(.ant-menu-item-selected):active,
    .ant-menu-light
      > .ant-menu:not(.ant-menu-horizontal)
      .ant-menu-item:not(.ant-menu-item-selected):active {
      color: $white;
      background-color: $primary;
    }

    .ant-menu .ant-menu-item,
    .ant-menu .ant-menu-submenu,
    .ant-menu .ant-menu-submenu-title {
      border-radius: 1.25rem;
    }
    .ant-menu-light .ant-menu-item-selected,
    .ant-menu-light > .ant-menu .ant-menu-item-selected {
      background-color: $transparent;
      color: $dark;
    }
  }
}
